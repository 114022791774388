import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProyectosService } from '@app/core/services/proyectos.service';
import { PROPIEDADES_PROYECTO_10, PROPIEDADES_PROYECTO_11, PROPIEDADES_PROYECTO_2, PROPIEDADES_PROYECTO_3, PROPIEDADES_PROYECTO_4, PROPIEDADES_PROYECTO_5, PROPIEDADES_PROYECTO_6, PROPIEDADES_PROYECTO_7, PROPIEDADES_PROYECTO_8, PROPIEDADES_PROYECTO_9, PROPIEDADES_PROYECTO_HIDROGENERAS } from '@app/mapa/mapa-data';
import { ClasesPerfilAnchoEnum } from '@app/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { IPerfilPropiedad } from '@app/neoShared/helpers/interfaces/IPerfilPropiedad';

export interface ISectionPropiedades  {
  propiedades?: IPerfilPropiedad[],
  visible: boolean,
  title: string
}
@Component({
  selector: 'neo-proyecto-detalle-personalizado',
  templateUrl: './proyecto-detalle-personalizado.component.html',
  styleUrls: ['./proyecto-detalle-personalizado.component.scss']
})
export class ProyectoDetallePersonalizadoComponent implements OnInit {
  @Input() proyecto = undefined;
  @Output() openProyectoAsociado = new EventEmitter<any>();

  // propiedadesProyecto = PROPIEDADES_PROYECTO;
  perfilAncho: ClasesPerfilAnchoEnum.perfilAnchoMedio;
  secciones: ISectionPropiedades[] = [
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_2},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_3},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_4},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_5},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_6},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_7},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_8},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_9},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_10},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_11},
    {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_HIDROGENERAS},
    // {title: '', visible: true, propiedades: PROPIEDADES_PROYECTO_12},
  ];
  constructor(
    private entidadService: ProyectosService
  ) { }

  ngOnInit(): void {
    this.secciones = this.secciones.map(proyecto => {
      let _proyecto = {...proyecto, propiedades: this.mappingPropiedades(proyecto.propiedades)};
      return _proyecto
    });
  }

  mappingPropiedades(section) {
    section.map(propiedad => propiedad.valor = (this.proyecto.hasOwnProperty(propiedad.identificador))
      ? (propiedad.identificador == 'actividadPrincipal' || propiedad.identificador == 'proyectoEstado') ? this.proyecto[propiedad.identificador]?.nombre : this.proyecto[propiedad.identificador]
      : ''
    );
    let propiedades = Object.assign([], section);
    propiedades = propiedades.filter(item => item.valor != '' && item.valor != null);
    return propiedades;
  }

  onOpenProyectoAsociado(data) {
    this.openProyectoAsociado.emit(data);
  }
}
