import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProyectosService } from '@app/core/services/proyectos.service';

@Component({
  selector: 'app-modo-vista',
  templateUrl: './modo-vista.component.html',
  styleUrls: ['./modo-vista.component.scss']
})
export class ModoVistaComponent implements OnInit {

  @Output() changeModoView = new EventEmitter<any>();

  // true mapa
  // false listado
  constructor(
    public proyectosService: ProyectosService
  ) { }

  ngOnInit(): void {
    
  }

  aplicarCambiarVista(activarVistaListado: boolean) {
    this.proyectosService.modoVista = (activarVistaListado);
    console.log('APLICAR CAMBIO MODO VISTA!!!! ', activarVistaListado);
    this.changeModoView.emit(activarVistaListado);
  }

}
