import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AG_GRID_LOCALE_ES } from './proyectos-tabla-data';

@Component({
  selector: 'neo-proyectos-tabla',
  templateUrl: './proyectos-tabla.component.html',
  styleUrls: ['./proyectos-tabla.component.scss']
})
export class ProyectosTablaComponent implements OnInit {

  @Input('data') data = [];
  
  // Comportamientos por defecto agGrid
  numberFilter =  (p) => {
    const value = p.data[p.colDef.field];
    const parsed = value && parseFloat(value.replace("%", "").replace("€", "").replace(",", "."));
    return parsed && !isNaN(parsed) ? parsed.toFixed(2) : '';
  }
  numberFilterParams = {
    allowedCharPattern: "\\d\\-\\,",
    numberParser: (text: string | null) => {
      return text == null
        ? null
        : parseFloat(text.replace("%", "").replace("€", "").replace(",", "."));
    },
    numberFormatter: (value: number | null) => {
      return value == null ? null : value.toString().replace(".", ",");
    },
    buttons: ["reset"],
  }
  
  columnDefs = [
    // { field: 'id' },
    // { headerName: 'Codigo', field: 'codigo'},
    { headerName: 'Nombre', field: 'nombre', tooltipField: 'nombre'},
    { headerName: 'Entidad líder', field: 'liderCoordinador', tooltipField: 'liderCoordinador'},
    { headerName: 'Otros Socios', field: 'otrosSocios', tooltipField: 'otrosSocios'},
    { headerName: 'Descripción', field: 'descripcion', tooltipField: 'descripcion'},
    {
      headerName: 'Tipo de proyecto',
      valueGetter: p => p.data.proyectoTipo.map(i => i.nombre).join(', '),
      tooltipValueGetter: p => p.data.proyectoTipo.map(i => i.nombre).join(', '),
    },
    {
      headerName: 'Incluye',
      valueGetter: p => p.data.proyectoIncluye.map(i => i.nombre).join(', '),
      tooltipValueGetter: p => p.data.proyectoIncluye.map(i => i.nombre).join(', '),
    },
    {
      headerName: 'Estado',
      valueGetter: p => p.data.proyectoEstado ? p.data.proyectoEstado.nombre : '',
      tooltipValueGetter: p => p.data.proyectoEstado ? p.data.proyectoEstado.nombre : '',
    },
    { headerName: 'FID (Final Investment Decision)', field: 'fid'},
    { headerName: 'Puesta en marcha', field: 'anoPuestaEnMarcha', filter: 'agNumberColumnFilter'},
    { headerName: 'En operación', field: 'enOperacion'},
    {
      headerName: 'Presupuesto Total (M€)',
      field: 'presupuestoTotal',
      filter: 'agNumberColumnFilter',
      filterValueGetter: this.numberFilter,
      filterParams: this.numberFilterParams,
    },
    {
      headerName: 'Financiación Pública',
      field: 'financiacionPublica',
      filter: 'agNumberColumnFilter',
      filterValueGetter: this.numberFilter,
      filterParams: this.numberFilterParams,
    },
    { headerName: 'Entidad financiadora', field: 'entidadFinanciadora', tooltipField: 'entidadFinanciadora'},
    { headerName: 'Capacidad Producción (toneladas/año)', field: 'capacidadProduccion', tooltipField: 'capacidadProduccion', filter: 'agNumberColumnFilter'},
    { headerName: 'Tecnología producción', field: 'tecnologiaDeProduccion', tooltipField: 'tecnologiaDeProduccion'},
    { headerName: 'Capacidad total electrolizadores (MW)', field: 'capacidadTotalElectrolizadores', filter: 'agNumberColumnFilter'},
    { headerName: 'Origen electricidad', field: 'origenElectricidad', tooltipField: 'origenElectricidad'},
    { headerName: 'CSS', field: 'incluyeCCS', tooltipField: 'incluyeCCS'},
    { headerName: 'Capacidad Almacenamiento (toneladas)', field: 'capacidadAlmacenamientoTotal'},
    { headerName: 'Tecnología almacenamiento', field: 'tecnologiaAlmacenamiento', tooltipField: 'tecnologiaAlmacenamiento'},
    { headerName: 'Presión almacenamiento (bar)', field: 'presionAlmacenamiento'},
    { headerName: 'Método de transporte/distribución', field: 'medioTransporteH2'},
    { headerName: 'Kms hidroductos', field: 'kmHidroductosDesplegar'},
    { headerName: 'Usos', field: 'usos', tooltipField: 'usos'},
    { headerName: 'Más info', field: 'masInfo', tooltipField: 'masInfo'},
    { headerName: 'HRS Capacidad Kg Día', field: 'capacidadKgDia'},
    { headerName: 'HRS Presión dispersación', field: 'presionDeDispersacion'},
    { headerName: 'HRS Acceso', field: 'acceso'},
    // {
    //   headerName: 'HRS Asociadas',
    //   valueGetter: p => p.data.proyectosAsociados.map(i => i.codigo).join(', '),
    //   tooltipValueGetter: p => p.data.proyectosAsociados.map(i => i.codigo).join(', '),
    // },

];
  localeTextES = AG_GRID_LOCALE_ES;
  defaultColDef = { 
    editable: true, 
    resizable: true,
    sortable: true,
    filter: true,
    autoSize: true,
    tooltipShowDelay: 0,
    enableBrowserTooltips: true,
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  // onGridReady(params) {
  //   params.api.sizeColumnsToFit();
  // }

}
