<div class="">
    <h2 class="text-p5 mb-10">
        {{proyecto.nombre}}
    </h2>

    <div *ngFor="let section of secciones; let i = index">
        <ng-container *ngIf="section.propiedades.length>0">
            <h4 *ngIf="section.title != ''">{{ section.title }}</h4>
            <neo-perfil [propiedades]="section.propiedades" [perfilAncho]="perfilAncho" (clickAction)="onOpenProyectoAsociado($event)" [aplicarFlex]="false"
                [perfilFormulario]="true">
            </neo-perfil>
        </ng-container>
    </div>
</div>
