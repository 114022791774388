import { FormTagEnum } from "@app/neoShared/helpers/enums/FormTag.enum";
import { TagCampoEnum } from "@app/neoShared/helpers/enums/TagCampo.enum";
import { PerfilPropiedadTagEnum } from "@app/neoShared/helpers/enums/perfil-propiedad-tag.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@app/neoShared/helpers/interfaces/IForm-input";
import { IPerfilPropiedad } from "@app/neoShared/helpers/interfaces/IPerfilPropiedad";
import * as L from 'leaflet';

// fila 2
export const PROPIEDADES_PROYECTO_2: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'liderCoordinador',
        label: 'Entidad líder / Coordinador del proyecto',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.ViewMore,
        identificador: 'otrosSocios',
        truncarTexto: 200,
        label: 'Otros Socios',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_3: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.ViewMore,
        identificador: 'descripcion',
        truncarTexto: 200,
        label: 'Descripción',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    }];
export const PROPIEDADES_PROYECTO_4: IPerfilPropiedad[] = [
    // fila 4
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.ArrayObjeto,
        identificador: 'proyectoTipo',
        label: 'Tipo de proyecto',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.ArrayObjeto,
        identificador: 'proyectoIncluye',
        label: 'Incluye',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_5: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'proyectoEstado',
        label: 'Estado ',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'fid',
        label: 'FID (Final Investment Decision) ',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    //  {
    //     tag: PerfilPropiedadTagEnum.Basico,
    //     tagCampo: TagCampoEnum.Input,
    //     identificador: 'anoPuestaEnMarcha',
    //     label: 'Año puesta en marcha',
    //     clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    // },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'enOperacion',
        label: 'En operación',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_6: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'presupuestoTotal',
        label: 'Presupuesto / Inversión total estimada (M€)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'financiacionPublica',
        label: '% Financiación pública',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'entidadFinanciadora',
        label: 'Entidad financiadora / Programa de subvención',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_7: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadProduccion',
        label: 'Capacidad de producción de hidrógeno (toneladas/año)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'tecnologiaDeProduccion',
        label: 'Tecnología de producción',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadTotalElectrolizadores',
        label: 'Capacidad total electrolizadores (MW)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'origenElectricidad',
        label: 'Origen de la electricidad',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'incluyeCCS',
        label: '',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    }];
export const PROPIEDADES_PROYECTO_8: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadAlmacenamientoTotal',
        label: 'Capacidad de almacenamiento (toneladas)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'tecnologiaAlmacenamiento',
        label: 'Tecnología de almacenamiento',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Cantidad,
        identificador: 'presionAlmacenamiento',
        label: 'Presión de almacenamiento (bar)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_9: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'medioTransporteH2',
        label: 'Método de transporte/distribución',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'kmHidroductosDesplegar',
        label: 'Km de hidroductos a desplegar',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.ArrayObjeto,
        keyArrayObjeto: 'codigo',
        ckickObjeto: true,
        identificador: 'proyectosAsociados',
        label: 'Hidrogeneras asociadas',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    }];
export const PROPIEDADES_PROYECTO_10: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Textarea,
        identificador: 'usos',
        label: 'Usos',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    }];
export const PROPIEDADES_PROYECTO_11: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Textarea,
        identificador: 'masInfo',
        label: 'Más información',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    }];
// export const PROPIEDADES_PROYECTO_12: IPerfilPropiedad[] = [
//     {
//         tag: PerfilPropiedadTagEnum.Basico,
//          tagCampo: TagCampoEnum.ArrayObjeto,
//         keyArrayObjeto: 'codigo',
//         identificador: 'proyectosAsociados',
//         label: 'Proyectos asociados',
//         clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
//     }
// ];

export const PROPIEDADES_PROYECTO_HIDROGENERAS: IPerfilPropiedad[] = [
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'capacidadKgDia',
        label: 'Capacidad (kg/día)',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'presionDeDispersacion',
        label: 'Presión de dispensación',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
    {
        tag: PerfilPropiedadTagEnum.Basico,
        tagCampo: TagCampoEnum.Input,
        identificador: 'acceso',
        label: 'Acceso',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x],
    },
];

export const MARGEN_TOLERANCIA_COORDENADAS =  0.001;

 export const INPUTSFILTRADO: IFormInput[] = [
    {
        tag: FormTagEnum.select,
        label: 'Tipo del proyecto',
        type: '',
        placeholder: 'Selección tipo proyecto',
        formControlName: 'idProyectoTipo',
        obligatorio: false,
        multiple: true,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'Estado del proyecto',
        type: '',
        placeholder: 'Selección estado proyecto',
        formControlName: 'idProyectoEstado',
        obligatorio: false,
        multiple: true,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'El proyecto incluye',
        type: '',
        placeholder: 'Selección incluye proyecto',
        formControlName: 'idProyectoIncluye',
        obligatorio: false,
        multiple: true,
        validadores: []
    },
    {
        tag: FormTagEnum.empty,
        label: 'Separador',
        type: '',
        placeholder: '',
        formControlName: '',
        obligatorio: false,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'Año desde',
        type: '',
        placeholder: 'Selección año',
        formControlName: 'minPuestaEnMarcha',
        obligatorio: false,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'Año hasta',
        type: '',
        placeholder: 'Selección año',
        formControlName: 'maxPuestaEnMarcha',
        obligatorio: false,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'MW electrólisis desde',
        type: '',
        placeholder: 'Selección capacidad',
        formControlName: 'minCapacidadTotalElectrolizadores',
        obligatorio: false,
        validadores: []
    },
    {
        tag: FormTagEnum.select,
        label: 'MW electrólisis hasta',
        type: '',
        placeholder: 'Selección capacidad',
        formControlName: 'maxCapacidadTotalElectrolizadores',
        obligatorio: false,
        validadores: []
    }    

];
export const IDCAMPO_API_PUESTAENMARCHA = 'puestaEnMarcha';
export const IDCAMPO_API_CAPACIDADTOTALELECTROLIZADORES = 'capacidadTotalElectrolizadores';

export const FILTROS_PUESTAENMARCHA = ['minPuestaEnMarcha', 'maxPuestaEnMarcha'];
export const DATOS_CAPACIDADTOTALELECTROLIZADORES = [0, 1, 5, 25, 50, 100, 250, 500, 1000];
export const EXTREMO_CAPACIDADTOTALELECTROLIZADORES = 9999999999999;

export const FILTROS_CAPACIDADTOTALELECTROLIZADORES = ['minCapacidadTotalElectrolizadores', 'maxCapacidadTotalElectrolizadores'];

