import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EXTREMO_CAPACIDADTOTALELECTROLIZADORES, FILTROS_CAPACIDADTOTALELECTROLIZADORES, FILTROS_PUESTAENMARCHA, IDCAMPO_API_CAPACIDADTOTALELECTROLIZADORES, IDCAMPO_API_PUESTAENMARCHA } from '@app/mapa/mapa-data';
import { IRespApi } from '@app/neoShared/helpers/interfaces/IResp-api';
import { AuxiliarService } from '@app/neoShared/services/auxiliar/auxiliar.service';
import { FILTRO_ORDEN_DEFECTO, UtilidadesCrudService } from '@appNeo/neoShared/services/utilidades/utilidades-crud.service';
import { BehaviorSubject, Observable, catchError, forkJoin, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProyectosService extends UtilidadesCrudService {
    private _modoVista: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(http: HttpClient, auxiliar: AuxiliarService) {
        super(
            http,
            auxiliar,
            'proyectos',
            'proyectos',
            'proyectos'.charAt(0).toUpperCase() + 'proyectos'.slice(1),
            'proyectos'.charAt(0).toUpperCase() + 'proyectos'.slice(1),
            [
                'id',
                'idProyectoTipo',
                'idProyectoEstado',
                'idProyectoIncluye',
                'minPuestaEnMarcha',
                'maxPuestaEnMarcha',
                'minCapacidadTotalElectrolizadores',
                'maxCapacidadTotalElectrolizadores'

            ]
        );
    }


    get modoVista$(): Observable<boolean> {
       return this._modoVista.asObservable();
    }

    set modoVista(modoVista: boolean) {
        this._modoVista.next(modoVista);
    }
    obtenerFiltros(filtros?: any) {
        let _filtros = { ...filtros, orden: (!filtros || !Object.entries(filtros).hasOwnProperty('orden')) ? FILTRO_ORDEN_DEFECTO : filtros.orden }

        // controlamos filtros no permitidos.
        if (_filtros && _filtros?.orden) {
            if (!this.filtrosOrdenPermitidosEntidad.includes(_filtros.orden)) {
                _filtros.orden = FILTRO_ORDEN_DEFECTO;
            }
        }
        if (filtros?.minCapacidadTotalElectrolizadores || filtros?.maxCapacidadTotalElectrolizadores) {
            _filtros [IDCAMPO_API_CAPACIDADTOTALELECTROLIZADORES] = 
                ((filtros.minCapacidadTotalElectrolizadores) ? filtros.minCapacidadTotalElectrolizadores :  0) + '-' +
                ((filtros.maxCapacidadTotalElectrolizadores) ? filtros.maxCapacidadTotalElectrolizadores : EXTREMO_CAPACIDADTOTALELECTROLIZADORES );
        }
       if (filtros?.minPuestaEnMarcha || filtros?.maxPuestaEnMarcha) {
            _filtros[IDCAMPO_API_PUESTAENMARCHA] = 
                ((filtros.minPuestaEnMarcha) ? filtros.minPuestaEnMarcha :  new Date().getFullYear() - 10 ) + '-' +
                ((filtros.maxPuestaEnMarcha) ? filtros.maxPuestaEnMarcha : new Date().getFullYear() + 10 );
        }
        FILTROS_PUESTAENMARCHA.forEach(item => delete _filtros[item]);
        FILTROS_CAPACIDADTOTALELECTROLIZADORES.forEach(item => delete _filtros[item]);
        return _filtros;
    }

    obtenerConfiguracionFiltros (): Observable<any> {
        let request = `${this.urlEntidades}/configuracionFiltros`;
        return this.http.get(request).pipe(
            catchError((err: IRespApi) => {
                return this.handleError(err, `obtenerConfiguracionFiltros${this.nombreEntidad}`);
            })
        );
    }
    /************************************ 
  * Funciones apoyo                  *
  *************************************/
    esProyectoTipoHidrogenera(proyecto) {
        const regex = /^HR[A-Za-z0-9]*$/;
        return regex.test(proyecto.codigo);
    } 

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let peticion = {
            listadoEntidad: this.getAll().pipe(
                catchError(err => of(null))
            )
        };
        const id = route.params.id;
        if (id) {
            peticion['entidad'] = this.get(id).pipe(
                catchError(err => of(null))
            )
        }
        return forkJoin(peticion);
    }
}
