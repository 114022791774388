import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuxiliarService } from '@app/neoShared/services/auxiliar/auxiliar.service';
import { UtilidadesCrudService } from '@app/neoShared/services/utilidades/utilidades-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProyectoIncluyeService extends UtilidadesCrudService{

  constructor(http: HttpClient, auxiliar: AuxiliarService) {
    super(
      http,
      auxiliar,
      'proyectoIncluye',
      'proyectoIncluye',
      'Proyecto Incluye',
      'Proyecto Incluye',
      [
        'id',
        'nombre'
      ]
    );
  }

}

