<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />
<script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossorigin=""></script>

<div class="row" [ngClass]="(entidadService.modoVista$ | async) ? 'header-mapa' : 'header-tabla'">
  <div class="col-6" style="padding-top: 8px">
    <app-modo-vista (changeModoView)="onChangeModoView($event)"></app-modo-vista>
  </div>

  <div class="col-6" style="text-align: right; padding: 2px 10px">
    <neo-button 
      *ngIf="(entidadService.modoVista$ | async)"
                    [text]="'Filtrar'"
                    [color]="'primary'"
                    [raised]="true"
                    [type]="'button'"
      [iconClass]="'icon-32 icon-ibm--filter'"
      [clase]="'minifab-flotante'"
      (accion)="abrirFiltros()">
    </neo-button>
  </div>
</div>

<div *ngIf="(entidadService.modoVista$ | async); else tablaView">
    <!-- Aquí iría el componente del mapa -->
    <div class="ubicacion" *ngIf="proyectos">
        <div class="mapa">
            <div class="action-leyenda">
                <neo-button *ngIf="true" [miniFab]="true" [color]="'primary'"
                    [iconClass]="'icon-60 icon-ibm--information'" [clase]="'minifab-flotante'"
                    (accion)="abrirInfoLeyenda($event)"></neo-button>
            </div>
            <div *ngIf="!mostrarErrorSinMapa" class="mapContainer" id="{{mapContainerId}}"></div>
        </div>

        <div class="sin-mapa" *ngIf="mostrarErrorSinMapa">
            <ion-img class="icon-40" src="assets/imgs/iconos/icon-lost-location.svg"></ion-img>
            <h2>No se ha podido localizar la ubicación</h2>
        </div>
    </div>
</div>

<ng-template #tablaView>
     <!-- Aquí iría el componente de la tabla -->
    <div>
        <div style="height: calc(100vh - 55px); width: 100%;" >
            <neo-proyectos-tabla [data]="getProyectosOrdenados()"></neo-proyectos-tabla>
        </div>
    </div>
</ng-template>

<!-- filtros responsivos ocultos -->
<ng-template #dialogFiltrosResponsivos>
  <neo-acciones-dialog
    [dialogTittle]="'Filtros'"
    [botonCerrarDialogoCabecera]=true
    [botonera]="botoneraDialogFiltrosResponsivos"
    (accionBoton)="submitAccionDialogFiltrosResponsivos($event)">
  
    <neo-busqueda #filtrosBusqueda
      placeholder="Busqueda"
      [botonLimpiarBuscador]="true"
      [valorDefault]="true"
      [keyFiltrado]="keyFiltrado"
      (limpiarBuscador)="onLimpiarBuscador()"
      (changeBuscador)="onEventoCambioBuscador($event)">
    </neo-busqueda>

    <neo-filtros-tabla
      [keyFiltrado]="keyFiltrado"
      [valoresIniciales]="valoresInicialesFiltrado"
      (cambioFormulario)="onEventoCambioFormularioFiltros($event)">
    </neo-filtros-tabla>
  </neo-acciones-dialog>
</ng-template>


