 <ag-grid-angular
      style="width: 100%; height: 100%;"
    class="ag-theme-alpine"
    [localeText]="localeTextES"
    [rowData]="data"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    rowSelection="multiple"
    [animateRows]="true"
    [alwaysShowHorizontalScroll]="true"
    [alwaysShowVerticalScroll]="true">
</ag-grid-angular>
<!-- (gridReady)="onGridReady($event)" -->