export enum TagCampoEnum {
    // perfil colaboracion con formulario
    Boolean = 'boolean', //*/* filtros formulario será combo, resto switch*/
    Checkbox = 'checkbox',
    Radio = 'radio',
    Clipboard = 'clipboard', //enlaces
    Contrasena = 'contrasena',
    Input = 'input',
    ViewMore = 'viewMore',
    Select = 'select', //select individual y multiple
    Estado = 'estado', //
    ChipsAutocomplete = 'chipsAutocomplete',
    InputFilter = 'inputFilter',
    Date = 'date', //*
    Importe = 'importe',//*
    Cantidad = 'cantidad',
    Telefono = 'telefono',
    Textarea = 'textarea',
    Captcha = 'captcha',
    Fichero = 'fichero',
    FicheroZonaArrastre = 'ficheroZonaArrastre',
    Editor = 'editor',

    // perfil colaboracion con tabla
    Enlace  = 'enlace', // incluye datos valor
    Editable = 'editable', //propiedad no tag aqui iria que tipo es de editable text cantidad inporte
    EnlacePrincipal    = 'enlacePrincipal',
    EnlaceSecundario    = 'enlaceSecundario',
    Accion  = 'accion', 
    Acciones  = 'acciones',
    Icono   = 'icono',
    Imagen = 'imagen',
    Selector = 'selector',
    Avatar = 'avatar' ,
    DespliegueMasInfo = 'despliegueMasInfo', //
    // otros a descartar, ser resolveran con un select
    Objeto  = 'objeto', // incluye datos valor
    ArrayObjeto  = 'arrayobjeto', // incluye datos valor
    ColorPicker = 'colorPicker',
    VerMas = 'verMas' // abre modal con, por defecto, una tabla con id/nombre. Permite pasar template custom.
}
     