import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'neo-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.scss'],
})
export class ViewMoreComponent implements OnInit {

  @Input() maxchars: any;
  @Input() texto: any;

  mostrarBotonVerMas:boolean = false;
  
  constructor(
    protected sanitizer: DomSanitizer
  ) { 
    if (!this.maxchars) {
      this.maxchars = '255';
    }
  }

  ngOnInit() {
    if (this.texto && this.texto.length > this.maxchars) {
      this.mostrarBotonVerMas = true;
    }
  }
  
  getTexto() {
    if (!this.mostrarBotonVerMas) {
      return this.sanitizer.bypassSecurityTrustHtml(this.texto);
    }
    if (this.texto.length <= this.maxchars) {
      return this.sanitizer.bypassSecurityTrustHtml(this.texto);
    }

    return this.sanitizer.bypassSecurityTrustHtml(this.texto.substring(0, this.maxchars) + '...');
  }

}
