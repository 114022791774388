import {Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import * as L from 'leaflet';
import {ProyectosService} from '@app/core/services/proyectos.service';
import 'leaflet.markercluster';
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import 'leaflet/dist/leaflet.css';

import {NgElement, WithProperties} from '@angular/elements';
import {ProyectoDetallePersonalizadoComponent} from './components/proyecto-detalle-personalizado/proyecto-detalle-personalizado.component';
import {environment} from '@environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {LeyendaMapaComponent} from './components/leyenda-mapa/leyenda-mapa.component';
import {TipoPaginadorEnum} from '@app/neoShared/helpers/enums/TipoPaginador.enum';
import {Paginator} from '@app/neoShared/models/paginator/paginator';
import {
  DATOS_CAPACIDADTOTALELECTROLIZADORES,
  FILTROS_CAPACIDADTOTALELECTROLIZADORES,
  FILTROS_PUESTAENMARCHA,
  IDCAMPO_API_PUESTAENMARCHA,
  INPUTSFILTRADO,
  MARGEN_TOLERANCIA_COORDENADAS
} from './mapa-data';
import {BusquedaComponent} from '@app/neoShared/components/busqueda/busqueda.component';
import {FiltrosTablaComponent} from '@app/neoShared/components/tabla/filtros-tabla/filtros-tabla.component';
import {FiltrosService} from '@app/neoCore/services/filtros/filtros.service';
import {FormularioFiltrosTablaService} from '@app/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import {IFormInput} from '@app/neoShared/helpers/interfaces/IForm-input';
import {FormularioService} from '@app/neoShared/services/formulario/formulario.service';
import {ActivatedRoute} from '@angular/router';
import {FormTagEnum} from '@app/neoShared/helpers/enums/FormTag.enum';
import {ProyectoTipoService} from '@app/core/proyectoTipo/proyecto-tipo.service';
import {ProyectoEstadoService} from '@app/core/proyectoEstado/proyecto-estado.service';
import {ProyectoIncluyeService} from '@app/core/proyectoIncluye/proyecto-incluye.service';
import {catchError, forkJoin, map, of} from 'rxjs';
import {OnDestroy} from '@angular/core/core';
import {TipoAccionBotonEnum} from '@app/neoShared/components/acciones-dialog/acciones-dialog.component';


@Component({
  selector: 'neo-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    FormularioService,
    // BotonDesplegableService,
    FiltrosService,
    FormularioFiltrosTablaService
  ],
})
export class MapaComponent implements OnInit, OnDestroy {
 // @ViewChild('dialogLeyenda') dialogLeyenda: TemplateRef<any>;
  
  proyectos = [];
  mostrarErrorSinMapa: boolean = false;
  cargandoOpenStreetMap: boolean = true;
  marcadorPersonalizado: boolean = true;
  dialogRef;
  markerIconDefault =
    L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })

  mapContainerId: string = '';

  map!: L.Map | undefined | null; 
  polylines: L.Polyline[] = []; // Array de polilíneas
  private maxZoomForPolyline = 7; // Nivel de zoom máximo para mostrar la polilínea

  customOptions: L.PopupOptions = {
    'className': 'customPopup', // name custom popup,
    'maxWidth': 800,
    'maxHeight': 500,
    'autoPan': true,
    'keepInView': true
  }
  markerCluster: L.MarkerClusterGroup = L.markerClusterGroup({
      spiderfyOnMaxZoom: false,  // Desactivar el comportamiento spiderfy mismas coordenadas
      iconCreateFunction: function (cluster) {
        var childCount = cluster.getChildCount();
        var cssClass = ' marker-cluster-';
        if (childCount < 5) {
          cssClass += 'small';
        } else if (childCount < 15) {
          cssClass += 'medium';
        } else {
          cssClass += 'large';
        }

        return new L.DivIcon({html: '<div><span>' + childCount + '</span></div>', className: 'marker-cluster' + cssClass, iconSize: new L.Point(40, 40)});
      },
			showCoverageOnHover: false,
		});
  marker = [];
  coordenadasCentro = {
    latitud: 40.4165000,
    longitud: -3.7025600
  }

  // filtrado
  precarga;
  numeroFiltros: number;
  @Input() keyFiltrado: string = 'mapa';
  filtradoSinBotonSubmit: boolean = false;
  @Input() valoresInicialesFiltrado: object;
  @ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;
  @ViewChildren(FiltrosTablaComponent) filtrosComponente: QueryList<FiltrosTablaComponent>;
  valoresFiltrado: object;
  cambioValoresFiltrado = false;
  cambioValorBuscador = false;
  @Output() clickFiltrar = new EventEmitter<any>();
  @Input() inputsFiltrado: IFormInput[] = INPUTSFILTRADO;
  paginator: Paginator = new Paginator();
  
	@ViewChild('dialogFiltrosResponsivos') dialogFiltrosResponsivos: TemplateRef<any>;
	botoneraDialogFiltrosResponsivos = [
    {
      label: 'Limpiar filtros',
      id: 'btn-limpiar',
      tipoAccion: TipoAccionBotonEnum.accion,
      type: 'button',
      color: 'primary',
      disabled: false,
      activo: true,
      basic: true,
      iconoClase: 'icon-20 icon-ibm--clean',
      matprefix: true
    },
    {
      label: 'Aplicar filtros',
      id: 'btn-filtrar',
      tipoAccion: TipoAccionBotonEnum.accion,
      type: 'button',
      color: 'primary',
      disabled: false,
      activo: true,
      flat: true
    }
  ];
  
  constructor(
    public entidadService: ProyectosService,
    private dialog: MatDialog,
    private filtrosService: FiltrosService,
    private filtrosTablaService: FormularioFiltrosTablaService,
    private activatedRoute: ActivatedRoute,
    // 
    private proyectoTipoService: ProyectoTipoService,
    private proyectoEstadoService: ProyectoEstadoService,
    private proyectoIncluyeService: ProyectoIncluyeService
  ) {
  }

  async ngOnInit() {
    // console.log('ngOnInit 1::configuracionFiltros');
    await this.configuracionFiltros();
    // console.log('ngOnInit 2::iniciarMapa');
    await this.iniciarMapa();
    // console.log('ngOnInit 3::loadProyectos');
    await this.loadProyectos(); // Si loadProyectos es una función asíncrona
    // this.inicializacion();
  }

  ngOnDestroy(): void {
    // console.log('ngOnDestroy::');
    this.eliminarMapa();
  }
  private eliminarMapa() {
    if (this.map) {
      this.map.remove();
    }
  }

  async iniciarMapa()  {
    // console.log('iniciarMapa ini::');
    this.mapContainerId = 'idMapContainer_' + Date.now();
    // console.log('iniciarMapa 2::', this.mapContainerId);
    this.cargandoOpenStreetMap = true; // Asegúrate de indicar que se está cargando

    await this.delay(200); // Esperar 200 milisegundos
    // console.log('iniciarMapa 3:: fin delay');
    this.cargandoOpenStreetMap = false;
    // console.log('iniciarMapa 4::->cargarMapa()');
    this.cargarMapa(this.coordenadasCentro.latitud, this.coordenadasCentro.longitud);
    // console.log('iniciarMapa fin::');
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async onChangeModoView(modoVistaMapa) {
    if (modoVistaMapa) {
      await this.iniciarMapa();
      await this.loadProyectos();
    } else {
        // REFACTORIZAR
        this.entidadService.getAll(TipoPaginadorEnum.online, undefined).subscribe({
            next: value => {
                this.proyectos  = value;
            },
            error: error => {
            },
            complete: () => {}
          });
    }
  }
  
  loadProyectos() {
    this.valoresFiltrado = Object.assign({}, this.obtenerValoresFiltrado());
    // console.log('DEBUG loadProyectos', this.valoresFiltrado);
    
    this.entidadService.getAll(TipoPaginadorEnum.online, undefined, this.valoresFiltrado).subscribe({
      next: value => {
        // console.log('loadProyectos 1::', value);
        // if (value.length) { // Si openStreetMap obtiene ubicación
        this.proyectos = this.agregarVariacionCoordenadas(value);
        // console.log('loadProyectos 2::updateMarkers');
        this.updateMarkers();
        // } else {
        //   this.mostrarErrorSinMapa = true;
        // }
      },
      error: error => {
        // console.log(error);
        this.mostrarErrorSinMapa = true;
      },
      complete: () => {
      }
    });
  }
  
  updateMarkers() {
    // console.log('updateMarkers() 1', ' Limpieza markerCluster, recorrido cada proyecto');
    // Borrar todas las capas y lineas antes de repintar
    this.polylines.forEach(polyline => {
        if (this.map.hasLayer(polyline)) {
          this.map.removeLayer(polyline);
        }
    })
    this.polylines = [];
    this.marker = [];
    this.markerCluster.clearLayers();
    this.proyectos.forEach(proyecto => {
      if (proyecto?.proyectoTipo) {
        //  console.log('updateMarkers() 2:: updateMarkerProyecto');
        if (!proyecto?.coordenadas || proyecto.coordenadas.length<=0) { 
            return;
        } else {
          if (proyecto.coordenadas.length==1) { 
            this.updateMarkerProyecto(proyecto);        
          }else {
            this.updatePolylineProyecto(proyecto);
          }
        }
      }
    });
    // console.log('updateMarkers() 3:: Añadimos a map markerCluster e iniciamos el cluster click');
    this.map.addLayer(this.markerCluster);
    
    // centrar mapa en posicion de los proyectosó
    this.map.fitBounds(L.latLngBounds(this.markerCluster.getLayers().map((layer: any) => layer.getLatLng())));
    
    // console.log('updateMarkers() 3:onClusterClick');
    this.onClusterClick();
  }
   // tiene que tener proyecto tipo
  updateMarkerProyecto(proyecto) {
    
    let iconoMarcador = this.obtenerMarcadorProyectoDefault();
    if (this.entidadService.esProyectoTipoHidrogenera(proyecto)) {
      // si es proyecto tipo hidrogenera icono prioritario. 
      iconoMarcador = this.obtenerMarcadorProyectoHidrogenera();
    } else {
      // mismas coordenadas proyectos, mostramos el icono del primer proyecto. Deberian tener mismo proyecto tipo. En caso de tener coordenadas pero no parametro tipo y/o estado, icono defecto
      iconoMarcador = this.obtenerMarcadorProyectoDefault();
    }
    
    let mark = L.marker([proyecto.coordenadas[0].latitud, proyecto.coordenadas[0].longitud], { icon: iconoMarcador });
    this.marker.push({id: proyecto.id, marker: mark});
    mark.bindTooltip(proyecto?.nombre);
    mark.bindPopup(fl => this.createPopupComponentWithMessage(proyecto), this.customOptions).openPopup();

    //  sin agrupacion iconos
    // this.map.addLayer(mark);

    // con agrupacion iconos
    this.markerCluster.addLayer(mark);
     
  }

  // mostrar solo en un max zoom
  updatePolylineProyecto(proyecto) {
    let coordenadas = proyecto.coordenadas.filter(item => item.latitud && item.longitud);
    
    if (coordenadas.length >=1 ) {
      let gasPipelineCoordinates = coordenadas.map(item => [parseFloat(item.latitud), parseFloat(item.longitud)]);
      let polyline = L.polyline( gasPipelineCoordinates , { color: this.getColorPolyline(proyecto), weight: 4,  opacity: 0.8 });
      polyline.feature = {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coordenadas.map(coord => [coord[1], coord[0]]) 
        },
        properties: {
            ...proyecto
        }
      };

      this.polylines.push(polyline);
      this.map.on('zoom', () => this.togglePolylinesVisibility());
      this.togglePolylinesVisibility();
    }
  }
  
  getColorPolyline(proyecto) {
    if (proyecto.codigo.includes('H2med')) {
      return '#a8c8a8';
    }
    
    return '#186718';
  }

  togglePolylinesVisibility() {
    const currentZoom = this.map.getZoom();
    this.polylines.forEach(polyline => {
      if (currentZoom <= this.maxZoomForPolyline) {
        if (!this.map.hasLayer(polyline)) {
          polyline.addTo(this.map)
          .on('click', () => {
            polyline.bindPopup(fl => this.createPopupComponentWithMessage(polyline.feature?.properties), this.customOptions).openPopup();
           
        });
        }
      } else {
        if (this.map.hasLayer(polyline)) {
          this.map.removeLayer(polyline);
        }
      }
    });
  }

  agregarVariacionCoordenadas(proyectos) {
    return proyectos.map(proyecto => {
         if (proyecto.coordenadas && proyecto.coordenadas.length > 0) {
            const nuevasCoordenadas = proyecto.coordenadas.map(coord => {
                if (coord.latitud && coord.longitud) {
                    return {
                        latitud: (parseFloat(coord.latitud) + 0.0001).toFixed(14),
                        longitud: (parseFloat(coord.longitud) + 0.0001).toFixed(14)
                    };
                } else {
                    return coord;
                }
            });
            return { ...proyecto, coordenadas: nuevasCoordenadas };
        } else {
            return proyecto;
        }
        
    });
  }
  cargarMapa(latitud: any, longitud: any) {
    this.map = L.map(this.mapContainerId, { 
      zoomControl: true,
      zoomSnap: 0.25,
    }).setView([latitud, longitud], 6);
    
    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
    }).addTo(this.map);
  }
 
  tienenMismaUbicacionEnCluster(markers: L.Marker[], margenCoordenadas = MARGEN_TOLERANCIA_COORDENADAS): boolean {
    if (markers.length === 0) return true; // Si no hay marcadores, devolvemos true

    const { lat, lng } = markers[0].getLatLng();

    return markers.every(marker => {
        const coords = marker.getLatLng();
        return this.comparaCoordenadas(coords.lat, lat, margenCoordenadas) && this.comparaCoordenadas(coords.lng, lng, margenCoordenadas)
    });
  } 

  onClusterClick() {
    // this.markerCluster.off('clusterclick');
    this.markerCluster.on('clusterclick', (a) => {
        const markersInCluster = a.layer.getAllChildMarkers();
        if (markersInCluster.length > 1 && this.tienenMismaUbicacionEnCluster(markersInCluster, MARGEN_TOLERANCIA_COORDENADAS)) { 
          const primerProyectoEnCluster = this.proyectos
            .filter(p => {
              console.log(p);
              return p.coordenadas.length > 0 &&
                p.coordenadas[0].latitud == markersInCluster[0].getLatLng().lat && 
                p.coordenadas[0].longitud == markersInCluster[0].getLatLng().lng
            })
          this.zoomClusterMismasUbicacionesOpenProyecto(primerProyectoEnCluster[0]);
        }
    });
  }
  
  private comparaCoordenadas(coord1, coord2, margenCoordenadas = MARGEN_TOLERANCIA_COORDENADAS)
  {
    return Math.abs(coord1 - coord2) <= margenCoordenadas;
  }
  
  public zoomClusterMismasUbicacionesOpenProyecto(proyectoReferencia) {
    const proyectoMarker = this.marker.find(p => p.id === proyectoReferencia.id);
    
    if (proyectoMarker) {
      this.map.setView(proyectoMarker.marker.getLatLng()); // Ajusta el nivel de zoom según sea necesario
      this.openPopupMarker(proyectoMarker.marker)
    }
  }

  openPopupMarker(marker) {
    this.markerCluster.zoomToShowLayer(marker, function() {
        // Cuando el marcador está visible, abrir el popup
        this.openPopup(marker);
    });
  }

  openPopup(marker) {
        marker.openPopup();
  }
  public createPopupComponentWithMessage(data: any) {
    const popupEl: NgElement & WithProperties<ProyectoDetallePersonalizadoComponent> = document.createElement('popup-element') as any;
    popupEl.proyecto = data;
    popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
    popupEl.addEventListener('openProyectoAsociado', (event: CustomEvent) => {
      // console.log('OPEN PROYECTO ASOCIADO', event);
      const proyectoAsociadoData = event.detail; // Recoge el data emitido
      // console.log('Proyecto asociado ', proyectoAsociadoData)
      this.onLimpiarBuscador();
      this.onSubmitFiltrado();
      setTimeout(()=>{
        this.openPopUpProyecto(proyectoAsociadoData?.objeto?.id);  // Llama al método para abrir el proyecto asociado con el dato recibido
      }, 400)
    });

    document.body.appendChild(popupEl);
    return popupEl;
  }

  private getIconoActividadPrinicipalProyectoEstado(idProyectoTipo, idProyectoEstado) {
    return environment.base_url_iconos + `/assets/icons/markers/marker_${idProyectoTipo}_${idProyectoEstado}.png`;
  }
  private obtenerMarcadorProyectoDefault() {
    const iconUrl = environment.base_url_iconos + `/assets/icons/markers/default.png`;
    return new L.Icon({
      iconUrl: iconUrl,
      iconSize: [45, 51],
      iconAnchor: [22.5, 51],
      popupAnchor: [0, -51],
    });
  }
  private obtenerMarcadorProyectoHidrogenera() {
    const iconUrl = environment.base_url_iconos + `/assets/icons/markers/hidrogenera.png`;
    return new L.Icon({
      iconUrl: iconUrl,
      iconSize: [45, 51],
      iconAnchor: [22.5, 51],
      popupAnchor: [0, -51],
    });
  }
  private obtenerMarcadorProyecto(idProyectoTipo, idProyectoEstado) {
    // console.log('idProyectoTipo ', idProyectoTipo, 'idProyectoEstado' , idProyectoEstado);
    let marcador = undefined;
    if (idProyectoTipo && idProyectoEstado) {
      const iconUrl = environment.base_url_iconos + `/assets/icons/markers/marker_${idProyectoTipo}_${idProyectoEstado}.png`;
      marcador = new L.Icon({
        iconUrl: iconUrl,
        iconSize: [45, 51],
        iconAnchor: [22.5, 51],
        popupAnchor: [0, -51],
      });
    }
    if (!marcador) {
      marcador = this.markerIconDefault;
    }
    return marcador;
  }
  
  async  openPopUpProyecto(proyectoId) {
   const proyectoMarker = this.marker.find(p => p.id === proyectoId);
    
    if (proyectoMarker) {
      // if (this.map.hasLayer(proyectoMarker.marker)) {
        // Ajusta el nivel de zoom según sea necesario
         this.map.setView(proyectoMarker.marker.getLatLng()); 
          proyectoMarker.marker.openPopup();
      // } else {
      //     console.log("El marcador no está en el mapa.");
      // }


    } else {
      console.error("Proyecto no encontrado");
    }
  }
  abrirInfoLeyenda() {
    this.map.closePopup();
    if (this.dialogRef) {
      this.dialog.closeAll();
    } else {
      this.dialogRef = this.dialog.open(LeyendaMapaComponent, { panelClass: 'dialogoLeyenda' });
    }

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = undefined;
    });
  }


  // **************************************** //
  // ******************* FILTRADO ********** //
  // **************************************** //
  async configuracionFiltros() {
    const peticion = {
      idProyectoTipo: this.proyectoTipoService.getAll(undefined, undefined, { orden: 'nombre', tipoOrden: 'asc' })
        .pipe(catchError(err => of(err))),
      idProyectoEstado: this.proyectoEstadoService.getAll(undefined, undefined, { orden: 'nombre', tipoOrden: 'asc' })
        .pipe(catchError(err => of(err))),
      idProyectoIncluye: this.proyectoIncluyeService.getAll(undefined, undefined, { orden: 'nombre', tipoOrden: 'asc' })
        .pipe(catchError(err => of(err))),
      configuracionFiltros: this.entidadService.obtenerConfiguracionFiltros()
        .pipe(
          map((data: any) => data['data']),
          catchError(err => of(err))
        ),
    };

    try {
      this.precarga = await forkJoin(peticion).toPromise();
      
      this.inputsFiltrado?.forEach((item, key, array) => {
        if (item.tag == FormTagEnum.select) {
          if (![...FILTROS_PUESTAENMARCHA, ...FILTROS_CAPACIDADTOTALELECTROLIZADORES].includes(item.formControlName)) {
            this.inputsFiltrado[key].datos = this.precarga[item.formControlName];
          }else {
            if ( FILTROS_PUESTAENMARCHA.includes(item.formControlName) ) {
                this.inputsFiltrado[key].datos = Array.from(
                    { length: Number(this.precarga.configuracionFiltros[IDCAMPO_API_PUESTAENMARCHA].max) - Number(this.precarga.configuracionFiltros[IDCAMPO_API_PUESTAENMARCHA].min) + 1 },
                    (_, i) => {
                        const anho = Number(this.precarga.configuracionFiltros[IDCAMPO_API_PUESTAENMARCHA].min) + i;
                        return { id: anho, nombre: anho };
                    }
                );
            } 
            if ( FILTROS_CAPACIDADTOTALELECTROLIZADORES.includes(item.formControlName) ) {
              this.inputsFiltrado[key].datos = DATOS_CAPACIDADTOTALELECTROLIZADORES.map(item => ({id: item, nombre: item}));
            } 
       
          }
        }
        if (item.tag == FormTagEnum.estado) {
          this.inputsFiltrado[key].datos = this.precarga[item.formControlName];
        }
      });
      this.establecerFiltros();
      this.numeroFiltros =  this.numeroFiltros + 1;
      this.valoresFiltrado = Object.assign({}, this.obtenerValoresFiltrado());
      if (JSON.stringify(this.valoresFiltrado) !== JSON.stringify(this.valoresInicialesFiltrado)) this.cambioValoresFiltrado = true;
      
    } catch (error) {
      console.error('Error al precargar datos:', error);
    }
  }

  getValoresFiltrado() {
    return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
  }

  onEventoCambioFormularioFiltros(formulario: Object){
    // console.log('DEBUG onEventoCambioFormularioFiltros ', formulario);
    this.determinarCambioValoresFiltradoIniciales(formulario);
    this.guardarFiltrosStorage(formulario);
    this.valoresFiltrado = Object.assign({}, formulario);
    if (this.filtradoSinBotonSubmit) {
      if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
      this.filtrar();
    }
  }
  
  determinarCambioValoresFiltradoIniciales(entidadFormulario) {
    JSON.stringify(entidadFormulario) !== JSON.stringify(this.valoresInicialesFiltrado) ? this.cambioValoresFiltrado = true : this.cambioValoresFiltrado = false;
  }
  


  onLimpiarBuscador() {
    // console.log('onLimpiarBuscador::');
    this.valoresFiltrado =  [];
    this.filtrosService.clearFiltros(this.keyFiltrado);
    if (this.filtrosBusqueda) {
     this.filtrosBusqueda.clearBuscador();
    }
    this.cambioValoresFiltrado = false;

  }
  onSubmitFiltrado() {
    // console.log('onSubmitFiltrado::');
    if (this.filtrosBusqueda?.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }
  onEventoCambioBuscador(valor){
    valor ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
    if (this.valoresFiltrado ) {
      this.valoresFiltrado['busqueda'] = valor;
    }
    // this.guardarFiltrosStorage(this.valoresFiltrado);
    // console.log('onEventoCambioBuscador::', valor, this.valoresFiltrado);
    // this.filtrar();
  }

  private filtrar() {
    // console.log('DEBUG filtrar')
    // console.log('filtrar::->loadProyectos()', );
    // console.log('[CALENDARIO EMIT] clickFiltrar', this.valoresFiltrado);
    // this.clickFiltrar.emit(this.valoresFiltrado);
    if (this.map) {
      this.loadProyectos()
    }
  }

  private obtenerValoresFiltrado() {
    return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
  }

  private establecerFiltros() {
    this.filtrosTablaService.inputsForm =  this.inputsFiltrado;
  }

  private guardarFiltrosStorage(valoresFiltrado: object) {
    let filtradoSinBusqueda = Object.assign({}, valoresFiltrado);
    // delete filtradoSinBusqueda['busqueda']; // TODO revisar-jose ¿esto para que era?
    this.filtrosService.setFiltros(this.keyFiltrado, filtradoSinBusqueda);
  }

  // onSubmitAccionDialogLeyenda() {
  //   this.dialog.closeAll();
  // }

  // abrirInfoLeyenda() {
  //   // COMPROBAR NO ABIERTO PROYECTO
  //   this.map.closePopup();
  //   this.dialog.open(this.dialogLeyenda);
  // }
  
	abrirFiltros(){
	  this.dialog.open(this.dialogFiltrosResponsivos, {disableClose: true});
	}
  
	submitAccionDialogFiltrosResponsivos(accionSeleccionada) {
    this.map.setZoom(6);
    switch (accionSeleccionada.id) {
      case 'btn-filtrar':
        this.onSubmitFiltrado();
        this.dialog.closeAll();
        break;
      case 'btn-limpiar':
        
        this.onLimpiarBuscador();
        this.onSubmitFiltrado();
        this.dialog.closeAll();
        break;
    }
  }
  
  getProyectosOrdenados() {
    return this.proyectos.sort((a, b) => {
      return a.nombre > b.nombre ? 1 : -1
    })
  }
}
