import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpContextToken } from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '@appNeo/neoCore/services/auth/auth.service';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
export const BYPASS_SIN_TOKEN = new HttpContextToken(() => false);
export const BYPASS_FRONT_TOKEN = new HttpContextToken(() => false);
export const BYPASS_FRONT_ANONIMO_TOKEN = new HttpContextToken(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string;
  tokenFront: string;
  constructor(
    private authService: AuthService
  ) {
    if (environment.debug_interceptor_api) {
      // console.log('Interceptor admin');
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.prepareRequest(request);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof  HttpResponse) {
          this.handleResponse(event);
        }
      })
    );
  }

  protected prepareRequest(request: HttpRequest<any>) {
    if (environment.debug_interceptor_api) {
      // console.log("[ ******************************************** ]");
      // console.log("[ Contexto Front ]", request.context.get(BYPASS_FRONT_TOKEN));
      // console.log("[ ******************************************** ]");
    }
    if (request.context.get(BYPASS_FRONT_TOKEN) == true) {
      this.token = this.authService.userFront?.token;
      if (environment.debug_interceptor_api) {
        // console.log('Token front ', this.token);
      }
    } else {
      this.token = this.authService.user?.token;
      if (environment.debug_interceptor_api) {
        // console.log('Token admin ', this.token);
      }
    }

    if (request.context.get(BYPASS_FRONT_ANONIMO_TOKEN) == true) {
      this.token = this.authService.userFrontAnonimo?.token;
      if (environment.debug_interceptor_api) {
        // console.log('Token front anonimo', this.token);
      }
    }
    if (request.context.get(BYPASS_SIN_TOKEN) == true || request.url.includes('maps.googleapis.com')) {
      this.token = undefined;
    }
    if (this.token) {
      // request = request.clone({
      //   headers: request.headers.set('Authorization', `Bearer ${this.token}`)
      // });
      request = request.clone({ setHeaders: { 'X-Token': this.token } });
      // request = request.clone({ setHeaders: { 'X-Token': this.token } });
    }
    if (environment.debug_interceptor_api) {
      // console.log('peticiones con token ', request);
    }
    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    if (environment.debug_interceptor_api) {
      // console.log('Peticion header ', request.headers);
    }
    return request;
  }

  protected handleResponse(resp: HttpResponse<any>) {
    const token = resp.headers.get('Token');
    if (token) {
      // this.authService.renovarToken(token);
    }
  }

}
