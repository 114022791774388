import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'neo-campo-range-slider',
  templateUrl: './campo-range-slider.component.html',
  styleUrls: ['./campo-range-slider.component.scss'],
})
export class CampoRangeSliderComponent implements OnInit {
  @Input() control!: FormControl;  // FormControl pasado desde el formulario
  @Input() min: number = 0;        // Valor mínimo del rango
  @Input() max: number = 100;      // Valor máximo del rango
  @Input() unidades: any = ''; 
  options!: Options;               // Opciones para configurar el ngx-slider

  ngOnInit(): void {
    // Configura las opciones del slider usando los valores de entrada `min` y `max`
    this.options = {
      floor: this.min,
      ceil: this.max,
      translate: (value: number): string => {
       let _unidades = (this.unidades) ?? '';
      return  value + _unidades;
    },
      // combineLabels: (minValue: string, maxValue: string): string => {
      //   return 'from ' + minValue + ' up to ' + maxValue;
      // }
    };
  
    this.resetValue();
  }

  resetValue() {
    // Inicializa el valor del slider con un rango si el FormControl es un objeto con `value` de tipo `{ min, max }`
    if (this.control && !this.control.value) {
      this.control.setValue({ min: this.min, max: this.max });
    }
  }
}